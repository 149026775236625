import React, { useImperativeHandle, forwardRef, useState, useEffect } from 'react';
import { Input, Col, Form, Row } from 'antd';
import axios from 'axios';

export const BlacklistEdit = forwardRef((props, ref) => {
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    setItem(props.item);
    setLoading(false);
    form.setFieldsValue({
      name: props.item.name,
    });
    form.setFieldsValue({
      blackValue: props.item.blackValue,
    });
  }, [props]);

  useImperativeHandle(ref, () => ({
    onSave() {
      form.submit();
    }
  }));

  const onFinish = (values) => {
    let jwttoken = sessionStorage.getItem("jwttoken");
    let blackItem = {
      id: item.id,
      name: values.name,
      blackValue: values.blackValue
    };

    if(blackItem.id > 0) {
      axios.put(`api/blacklist/${item.id}`, blackItem,{ headers: { "Authorization": `bearer ${jwttoken}` } })
        .then(res => {
          props.onSave(`Item id:${blackItem.id} was successfully updated`);
        })
        .catch(error => {
          props.onError(error.message);
        });
    }
    else {
      axios.post(`api/blacklist`, blackItem,{ headers: { "Authorization": `bearer ${jwttoken}` } })
        .then(res => {
          props.onSave(`Item was successfully added to blacklist`);
        })
        .catch(error => {
          props.onError(error.message);
        });
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  function renderForm() {
    return (
      <Form layout="vertical" 
        form={form} 
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        hideRequiredMark>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter name',
                },
              ]}
            >
              <Input placeholder="Please enter name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="blackValue"
              label="Blacklist mobile or email"
              rules={[
                {
                  required: true,
                  message: 'Please enter blacklist value',
                },
              ]}
            >
              <Input placeholder="Please enter blacklist value" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }


  let content = loading ? <></> : renderForm();

  return (
    <>
      {content}
    </>
  );
});