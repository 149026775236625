import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import moment from 'moment/moment';
import { GlobalOutlined } from '@ant-design/icons';
import { LogPopup } from './LogPopup';
import { FilterDropDown } from './FilterDropDown';


export const RaceReplaysList = () => {
  const [loading, setLoading] = useState(true);
  const [data, setdata] = useState([]);
  const [searchParams, setSearchParams] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const navigate = useNavigate();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });


  useEffect(() => {
    loadData();
    const timeoutId = setInterval(() => {
      loadData();
    }, "5000");
    return () => clearInterval(timeoutId);
  }, [JSON.stringify(tableParams),JSON.stringify(searchParams)]);

  const loadData = () => {
    let searchQuery = Object.entries(searchParams)
        .map(([key, value]) => `&${key}=${value}`
      ).join('');

    let jwttoken = sessionStorage.getItem("jwttoken");
    axios.get(`api/racereplays?page=${tableParams.pagination.current}&pageSize=${tableParams.pagination.pageSize}${searchQuery}`, { headers: { "Authorization": `bearer ${jwttoken}` } })
      .then(res => {
        setdata(res.data.results);
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.rowCount,
          },
        });
      })
      .catch(error => {
        if (error.request.status === 401) {
          navigate('/login');
        }
        console.log(error);
      });
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    let obj = { ...searchParams, [dataIndex]: selectedKeys };
    setSearchParams(obj);
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setdata([]);
    }
  };

  const getColumnSearchProps = (dataIndex, filterType) => ({
    filterDropdown: (props) => {
      return (
        <FilterDropDown 
          {...props}
          filterType={filterType}
          dataIndex={dataIndex}  
          handleSearch={handleSearch} 
          handleReset={handleReset} 
        />
      )
    },
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    // onFilter: (value, record) =>
    //   record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    // onFilterDropdownOpenChange: (visible) => {
    //   if (visible) {
    //     setTimeout(() => searchInput.current?.select(), 100);
    //   }
    // },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: 'Date',
      dataIndex: 'raceDayDate',
      key: 'raceDayDate',
      width: '25%',
      className: 'nowrap',
      ...getColumnSearchProps('raceDayDate', 'dateFilter'),
      render: (record) => {
        return (moment(record).format("YYYY-MM-DD"))
      }
    },
    {
      title: 'Track',
      dataIndex: 'trackName',
      key: 'trackName',
      width: '20%',
      ...getColumnSearchProps('trackName'),
    },
    {
      title: 'Race',
      dataIndex: 'raceNr',
      key: 'raceNr',
      ...getColumnSearchProps('raceNr', 'raceNrFilter'),
      sorter: (a, b) => a.raceNr.length - b.raceNr.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'PostTime',
      dataIndex: 'postTime',
      key: 'postTime',
      className: 'nowrap',
      ...getColumnSearchProps('postTime'),
      render: (record) => {
        let date = new Date(record);
        return date.toLocaleString('sv-SE', { timeStyle: "short" })
      }
    },
    {
      title: 'RaceExported',
      dataIndex: 'exported',
      key: 'exported',
      className: 'nowrap',
      ...getColumnSearchProps('exported'),
      render: (record) => {
        let date = new Date(record);
        return record ? date.toLocaleString('sv-SE', { timeStyle: "short" }) : ''
      }
    },
    {
      title: 'FinalExported',
      dataIndex: 'finalExported',
      key: 'finalExported',
      className: 'nowrap',
      ...getColumnSearchProps('finalExported'),
      render: (record) => {
        let date = new Date(record);
        return record ? date.toLocaleString('sv-SE', { timeStyle: "short" }) : ''
      }
    },
    {
      title: 'Distance',
      dataIndex: 'distance',
      key: 'distance',
      ...getColumnSearchProps('distance'),
      sorter: (a, b) => a.distance.length - b.distance.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Speed',
      dataIndex: 'speed',
      key: 'speed',
      ...getColumnSearchProps('speed'),
      sorter: (a, b) => a.speed.length - b.speed.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Race',
      dataIndex: 'guid',
      key: 'guid',
      render: (record, item) => {
        return (
          item.exported ? <a href={`http://trmedia.devserver.phosdev.se/api/mediarequest/${record}/download`} title='Link to race' target='_blank' rel="noreferrer"><GlobalOutlined /></a> : ''
        )
      }
    },
    {
      title: 'Final',
      dataIndex: 'guid',
      key: 'guid',
      render: (record, item) => {
        return (
          item.finalExported ? <a href={`http://trmedia.devserver.phosdev.se/api/mediarequest/${record}/download/final`} title='Link to final' target='_blank' rel="noreferrer"><GlobalOutlined /></a> : ''
        )
      }
    },
    {
      title: '',
      dataIndex: 'processLog',
      key: 'processLog',
      width: '10%',
      render: (record) => {
        return (
          record ? <LogPopup log={record} /> : ''
        )
      }
    },
  ];

  return <Table
    columns={columns}
    dataSource={data}
    pagination={tableParams.pagination}
    onChange={handleTableChange}
    loading={loading}
  />;
};
