import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, message, Drawer, Select, Popconfirm, Checkbox } from 'antd';
import React,{ useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import { ExtraRecipientEdit } from './ExtraRecipientEdit';
import {
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

const { Option } = Select;

export const ExtraRecipientsList = () => {
  const [data, setdata] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [open, setOpen] = useState(false);
  const [recipient, setRecipient] = useState(null);
  const editRef = useRef();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onSave = () => {
    editRef.current.onSave();
  };

  const handleOnSave = (message) => {
    populateData();
    success(message);
    onClose();
  };

  const handleOnError = (message) => {
    error(message);
  };

  const success = (message) => {
    messageApi.open({
      type: 'success',
      content: message,
    });
  };

  const error = (message) => {
    messageApi.open({
      type: 'error',
      content: message,
    });
  };

  const editItem = (item) => {
    setRecipient(item);
    showDrawer();
  };

  const newItem = () => {
    setRecipient({id: 0, firstName: "", lastName: "", phone: "", mobile: "", active: true});
    showDrawer();
  };

  const confirmDelete = (item) => {
    let jwttoken = sessionStorage.getItem("jwttoken");
    axios.delete(`api/extrarecipients/${item.id}`,{ headers: { "Authorization": `bearer ${jwttoken}` } })
        .then(res => {
          populateData();
          success(`Item id:${item.id} was successfully deleted`);
        })
        .catch(err => {
          if(error.request.status == 401) {
            navigate('/login');
          }
          error(err.message);
        });
  };

  const cancel = (e) => {
    console.log(e);
  };
  
  useEffect(() => {
    populateData();
  }, []);

  const populateData = () => {
    let jwttoken = sessionStorage.getItem("jwttoken");
    axios.get(`api/extrarecipients`,{ headers: { "Authorization": `bearer ${jwttoken}` } })
        .then(res => {
            setdata(res.data);
        })
        .catch(error => {
          if(error.request.status == 401) {
            navigate('/login');
          }
            console.log(error);
        });
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const columns = [
    
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
      ...getColumnSearchProps('id'),
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      ...getColumnSearchProps('active'),
      render: (text, record) => {
        return (
          <Checkbox checked={text} disabled/>
        )
      }
    },
    {
      title: 'Firstname',
      dataIndex: 'firstName',
      key: 'firstName',
      width: '30%',
      ...getColumnSearchProps('firstName'),
    },
    {
      title: 'Lastname',
      dataIndex: 'lastName',
      key: 'lastName',
      width: '30%',
      ...getColumnSearchProps('lastName'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '20%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      key: 'mobile',
      width: '20%',
      ...getColumnSearchProps('mobile'),
    },
    
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <Space size={"middle"}>
            <EditOutlined onClick={() => editItem(record)} />
            <Popconfirm
              title="Delete item"
              description="Are you sure to delete this item?"
              onConfirm={() => confirmDelete(record)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined />
            </Popconfirm>
          </Space>
        )
      }
    }
  ];
  return (
    <>
      {contextHolder}
      <Table columns={columns} dataSource={data}>
      </Table>
      <Button type="primary" onClick={newItem} icon={<PlusOutlined />}>
        Add
      </Button>
      <Drawer
        title={recipient?.id>0?'Edit item':'New item'}
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={onSave} type="primary">
              Save
            </Button>
          </Space>
        }
      >
        <ExtraRecipientEdit item={recipient} onSave={handleOnSave} onError={handleOnError} ref={editRef} />
      </Drawer>
    </>
  );
};