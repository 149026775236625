import { Home } from "./components/Home";
import { Blacklist } from "./components/Blacklist";
import { Races } from "./components/Races";
import { ExtraRecipients } from "./components/ExtraRecipients";
import { Login } from "./components/Login";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/races',
    element: <Races />
  },
  {
    path: '/blacklist',
    element: <Blacklist />
  },
  {
    path: '/extrarecipients',
    element: <ExtraRecipients/>
  },
  {
    path: '/login',
    element: <Login/>
  }
];

export default AppRoutes;
