
import React from 'react';
import { RaceReplaysList } from './controls/RaceReplaysList';
import { MainLayout } from './MainLayout';

export const Races = () => {
  return (
      <MainLayout selectedMenu={'sub2-1'}>
        <RaceReplaysList/>
      </MainLayout>
  );
};
 