import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { TrophyOutlined, RocketOutlined } from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';

const { Content, Sider } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type
  };
}

const items2 = [
  getItem('VideoGrattis', 'sub1', <TrophyOutlined/>, [
    getItem('Videos', 'sub1-1'),
    getItem('Blacklist', 'sub1-2'),
    getItem('Extra recipients', 'sub1-3')
  ]),
  getItem('RaceReplays', 'sub2', <RocketOutlined />, [
    getItem('Races', 'sub2-1'),
  ])
]

export const MainLayout = (props) => {
  const [selectedMenu, setSelectedMenu] = useState();
  const navigate = useNavigate();

    useEffect(() => {
      let username = sessionStorage.getItem("username");
      if(username === '' || username === null) {
        navigate('/login');
      }
    });

    useEffect(() => {
      setSelectedMenu(props.selectedMenu);
    }, [props]);

    const menuClick = (item, key, keyPath, domEvent) => {
      if(item.key == 'sub1-2')
        navigate('/blacklist');
      if(item.key == 'sub1-3')
        navigate('/extrarecipients');
      if(item.key == 'sub1-1')
        navigate('/');
        if(item.key == 'sub2-1')
        navigate('/races');
    }

    const {
      token: { colorBgContainer },
    } = theme.useToken();

    return (
      <div>
        <NavMenu />
        <Container>
          <Layout>
            <Layout>
              <Sider
                width={200}
                style={{
                   background: colorBgContainer,
                }}
              >
                <Menu
                  mode="inline"
                  selectedKeys={selectedMenu}
                  defaultOpenKeys={['sub1','sub2']}
                  style={{
                    height: '100%',
                    borderRight: 0,
                  }}
                  onClick={menuClick}
                  items={items2}
                />
              </Sider>
              <Layout
                style={{
                  padding: '0 24px 24px',
                  backgroundColor: '#fff'
                }}
              >
                <Content
                  style={{
                    padding: "0,24",
                    margin: 0,
                    minHeight: 280,
                    background: colorBgContainer,
                  }}
                >
                {props.children}
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </Container>
      </div>
    );
}
