import React, { useImperativeHandle, forwardRef, useState, useEffect } from 'react';
import { Input, Col, Form, Row, Checkbox } from 'antd';
import axios from 'axios';

export const ExtraRecipientEdit = forwardRef((props, ref) => {
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    setItem(props.item);
    setLoading(false);
    form.setFieldsValue({
      firstName: props.item.firstName,
    });
    form.setFieldsValue({
      lastName: props.item.lastName,
    });
    form.setFieldsValue({
      email: props.item.email,
    });
    form.setFieldsValue({
      mobile: props.item.mobile,
    });
    form.setFieldsValue({
      active: props.item.active,
    });
  }, [props]);

  useImperativeHandle(ref, () => ({
    onSave() {
      form.submit();
    }
  }));

  const onFinish = (values) => {
    let jwttoken = sessionStorage.getItem("jwttoken");
    let recipient = {
      id: item.id,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      mobile: values.mobile,
      active: values.active
    };

    if(recipient.id > 0) {
      axios.put(`api/extrarecipients/${item.id}`, recipient,{ headers: { "Authorization": `bearer ${jwttoken}` } })
        .then(res => {
          props.onSave(`Item id:${recipient.id} was successfully updated`);
        })
        .catch(error => {
          props.onError(error.message);
        });
    }
    else {
      axios.post(`api/extrarecipients`, recipient,{ headers: { "Authorization": `bearer ${jwttoken}` } })
        .then(res => {
          props.onSave(`Item was successfully added`);
        })
        .catch(error => {
          props.onError(error.message);
        });
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  function renderForm() {
    return (
      <Form layout="vertical" 
        form={form} 
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        hideRequiredMark>
          <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="active"
              label="Active"
              valuePropName='checked'
            >
              <Checkbox>Yes</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter first name',
                },
              ]}
            >
              <Input placeholder="Please enter first name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter last name',
                },
              ]}
            >
              <Input placeholder="Please enter last name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="email"
              label="Email"
            >
              <Input placeholder="Please enter email" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="mobile"
              label="Mobile"
            >
              <Input placeholder="Please enter mobile" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }


  let content = loading ? <></> : renderForm();

  return (
    <>
      {content}
    </>
  );
});