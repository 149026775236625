
import React from 'react';
import { BlacklistList } from './controls/BlacklistList';
import { MainLayout } from './MainLayout';

export const Blacklist = () => {
  return (
      <MainLayout selectedMenu={'sub1-2'}>
        <BlacklistList/>
      </MainLayout>
  );
};
