import React,{ useState, useEffect } from 'react';
import { Popover, Button } from 'antd';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import moment from 'moment/moment';

export const RecipientPopup = (props) => {
  const [recipients, setRecipients] = useState([]);
  const [content, setContent] = useState([]);

  useEffect(() => {
    setRecipients(props.recipients);
    var c = renderContent(props.recipients);
    setContent(c);
  }, []);

  const renderContent = (recs) => {
    var list = recs.map(recipient => 
        <div key={`rec-${recipient.id}`}>{moment(recipient.created).format("HH:mm")} {recipient.lastName} {recipient.mobile ? <PhoneOutlined /> : ""} {recipient.email ? <MailOutlined /> : ""}</div>
    );

    return (
        <div>
            {list}
        </div>
    )
  }

    return (
        <Popover content={content} title="Recipients">
            <Button size='small' style={{width:"50px"}} icon={<MailOutlined />}> {recipients.length}</Button>
        </Popover>
    )
}