import React from 'react';
import { ExtraRecipientsList } from './controls/ExtraRecipientsList';
import { MainLayout } from './MainLayout';

export const ExtraRecipients = () => {
  return (
      <MainLayout selectedMenu={'sub1-3'}>
        <ExtraRecipientsList/>
      </MainLayout>
  );
};
