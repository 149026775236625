
import React from 'react';
import { NavbarBrand } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom'
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space, Card, message } from 'antd';
import axios from 'axios';

export const Login = () => {
  const usenavigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const onFinish = (values) => {
    let credentials = {username: values.username, password: values.password};

    axios.post(`api/user/authenticate`, credentials)
        .then(res => {
            sessionStorage.setItem('username', res.data.username);
            sessionStorage.setItem('jwttoken', res.data.jwttoken);
            usenavigate('/');
        })
        .catch(error => {
            messageApi.open({
              type: 'error',
              content: `Login failed: "${error.response.statusText}"`,
            });
        });
  };

  return (
      <>
      {contextHolder}
      <div
        className="row align-items-center text-light"
        style={{ minHeight: "100vh"}}>
      <Space direction="vertical" align='center' size="middle" style={{ display: 'flex' }}>
        <Card title={<NavbarBrand tag={Link} to="/" className='lbVyER'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 230.205 52.85" focusable="false" role="img" className="z__sc-bn0hko-0 gLMJsD z__sc-gg71u6-0 kaqksj"><path fill="#c52b23" d="M48.8 16.782a16.219 16.219 0 0 0-11.4 4.226 16.324 16.324 0 0 0-11.628-4.442h-6.824V0h-9.44v16.566H0v9.44h9.508v10.427c0 9.3 6.365 16.233 16.234 16.233.369 0 .733-.012 1.092-.03v-8.985a8.458 8.458 0 0 1-1.092.076c-4.72 0-6.794-3.433-6.794-6.793V26.006h7.32c3.33 0 6.725 2.039 6.788 6.666v20.176H42V32.8c0-.1 0-.191-.006-.287h.008c0-3.362 2.075-6.795 6.8-6.795 4.648 0 6.722 3.433 6.722 6.795v3.5h9.44v-3c-.004-9.294-6.297-16.231-16.164-16.231z"></path><path fill="#fff" d="M76.563 16.564h2.7l16.38 23.585L112 16.564h2.7V52.85h-2.453V20.261l-16.6 23.9-16.628-23.9V52.85h-2.458m67.448-34.071h-18.021v13.166h17.558v2.162h-17.558v16.578h18.021v2.163h-20.793V16.617h20.793zm8.491 34.069V16.617h7.146a29.963 29.963 0 0 1 8.171.984 17.845 17.845 0 0 1 6.547 3.581 17.107 17.107 0 0 1 6.31 13.6 16.909 16.909 0 0 1-6.1 13.263 17.38 17.38 0 0 1-6.625 3.771 29.673 29.673 0 0 1-8.3 1.034zm2.524-2.163h4.57q8.456 0 13.3-4.373a15.419 15.419 0 0 0-.473-23.593 15.7 15.7 0 0 0-5.758-3.075 26.177 26.177 0 0 0-7.067-.865h-4.57zm33.77-34.068v36.231h-2.773V16.617zm32.748 24.314h-19.118l-5.833 11.917h-2.83l18.251-37.432 18.193 37.432h-2.83zm-1.04-2.163-8.49-17.491-8.548 17.491z"></path></svg>
        </NavbarBrand>} headStyle={{backgroundColor: "dimgray", paddingBottom: "8px"}}>
        
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              style={{width:"22rem"}}
            >
              <Form.Item
                name="username"
                rules={[{ required: true, message: 'Please input your Username!' }]}
              >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your Password!' }]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button">
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Space>
        </div>
      </>
  );
};
