import React,{ useState, useEffect } from 'react';
import { Popover, Button } from 'antd';
import { ExceptionOutlined } from '@ant-design/icons';

export const LogPopup = (props) => {
  const [log, setLog] = useState([]);
  const [content, setContent] = useState([]);

  useEffect(() => {
    setLog(props.log);
    var c = renderContent(props.log);
    setContent(c);
  }, []);

  const renderContent = (log) => {
    return (
        <div>
          <p dangerouslySetInnerHTML={{__html: log.replace(/\n/g, "<br />")}} />
        </div>
    )
  }

    return (
        <Popover content={content} title="Log">
            <Button size='small' style={{width:"50px"}} icon={<ExceptionOutlined />}></Button>
        </Popover>
    )
}