
import React from 'react';
import { VideoGrattisList } from './controls/VideoGrattisList';
import { MainLayout } from './MainLayout';

export const Home = () => {
  return (
      <MainLayout selectedMenu={'sub1-1'}>
        <VideoGrattisList/>
      </MainLayout>
  );
};
 