import React from 'react';
import { Button, DatePicker, Input, Space, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

export const FilterDropDown = (props) => {
    const onChange = (date, dateString) => {
        props.setSelectedKeys(dateString ? [dateString] : [])
      };

    const renderType = () => {
        if(props.filterType=='dateFilter') {
            return (
                <DatePicker 
                    onChange={onChange}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
            )
        }
        else if(props.filterType=='raceNrFilter') {
            var options = [{value: '', label: 'Alla'}];
            var counter = 1;
            while(counter < 15){
                options.push({value: counter.toString(), label: counter.toString()});
                counter++;
            }
            return (
                <Select
                    defaultValue="Alla"
                    style={{
                        width: 120,
                        marginBottom: 8,
                        display: 'block',
                    }}
                    onChange={(e) => props.setSelectedKeys(e ? [e] : [])}
                    options={options}
                />
            )
        }
        else {
            return (
                <Input
                    ref={props.searchInput}
                    placeholder={`Search ${props.dataIndex}`}
                    value={props.selectedKeys[0]}
                    onChange={(e) => props.setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => props.handleSearch(props.selectedKeys, props.confirm, props.dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
            )
        }
    }

    return(
        <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        {renderType()}
        <Space>
          <Button
            type="primary"
            onClick={() => props.handleSearch(props.selectedKeys, props.confirm, props.dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => props.clearFilters && props.handleReset(props.clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              props.confirm({
                closeDropdown: false,
              });
              props.setSearchText(props.selectedKeys[0]);
              props.setSearchedColumn(props.dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              props.close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    )
}